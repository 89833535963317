import React from 'react'
import WhyApply from './WhyApply'
import './campusBanner.css'
import Data from './data'

const Apply = () => {

    return (
        <>
            <section className='ApplySec'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12 text-center ApplyDiv">
                            <h4 className='text-uppercase'>Why should you apply?</h4>
                        </div>
                    </div>

                    <div className="row gy-4">
                        {
                            Data.map((data, index) => {
                                return(
                                    <div key={index} className="col-12 col-lg-12 ApplyMainDiv">
                                        <div className={`ApplyComponent ${data.ind % 2 == 0 ? "float-end" : ""}`}>
                                        {/* <div className={`ApplyComponent float-end`}> */}
                                            <WhyApply img={data.img} heading={data.heading} para={data.para}/> 
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div> 
                </div>
            </section>
        </>
    )
}

export default Apply

{/* <div className="row gy-5 d-flex flex-column">
    <div className="col-12">
        <div className="ApplyComponent float-end">
            <WhyApply/> 
        </div>
    </div>
    <div className="col-12">
        <div className="ApplyComponent">
            <WhyApply/> 
        </div>
    </div>
</div> */}