import React, { useEffect, useState } from 'react';
import img from './img/gift.png';

const RewardSection = () => {
    const [showRewards, setShowRewards] = useState(false);
    const [typingIndex, setTypingIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (typingIndex < 8) {
                setShowRewards(true);
                setTypingIndex(typingIndex + 1);
            } else {
                setShowRewards(false);
                setTypingIndex(0);
            }
        }, 500);

        return () => clearInterval(interval);
    }, [typingIndex]);
    const [currentColorIndex, setCurrentColorIndex] = useState(0);
    const colors = ['#3972af', '#2e547c'];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentColorIndex(prevIndex => (prevIndex + 1) % 4);
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    return (
        <section className='RewardSec'>
            <div className="container text-center">
                <h1 className="py-3">
                    Unleash Your Potential with Exclusive Ambassador{' '}
                    <span className="reward-word">
                        <span className={`reward-letter ${typingIndex >= 1 ? 'visible' : ''}`}>R</span>
                        <span className={`reward-letter ${typingIndex >= 2 ? 'visible' : ''}`}>E</span>
                        <span className={`reward-letter ${typingIndex >= 3 ? 'visible' : ''}`}>W</span>
                        <span className={`reward-letter ${typingIndex >= 4 ? 'visible' : ''}`}>A</span>
                        <span className={`reward-letter ${typingIndex >= 5 ? 'visible' : ''}`}>R</span>
                        <span className={`reward-letter ${typingIndex >= 6 ? 'visible' : ''}`}>D</span>
                        <span className={`reward-letter ${typingIndex >= 7 ? 'visible' : ''}`}>S</span>
                        <span className={`reward-letter ${typingIndex >= 8 ? 'visible' : ''}`}>!</span>
                    </span>
                </h1>
                <div id="reward" className="py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-12 imgCol">
                                <div className="reward-image">
                                    <img src={img} alt="giftImg" />
                                </div>
                            </div>
                            <div className="col-xl-6 col-12">
                                <div className="row pt-lg-5">
                                    <div className="col-md-6 col-12">
                                        <div className="reward-box" style={{ backgroundColor: currentColorIndex === 0 ? colors[0] : colors[1] }}>
                                            <h5>Certificate</h5>
                                            <p>Upon completing your Campus Ambassador tenure and internship, you'll receive a certificate recognizing your achievements. Successful candidates will also enjoy a complimentary internship opportunity.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="reward-box" style={{ backgroundColor: currentColorIndex === 3 ? colors[0] : colors[1] }}>
                                            <h5>Free Course</h5>
                                            <p>Access an invaluable complimentary company course and exclusive masterclasses on resume building, cutting-edge AI tools, and additional skill enhancement, fostering your professional development and career growth.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="reward-box" style={{ backgroundColor: currentColorIndex === 1 ? colors[0] : colors[1] }}>
                                            <h5>Offer Letter/LOR</h5>
                                            <p>
                                                Exceptional performance and dedication could potentially earn you a glowing letter of recommendation from the company, as well as the possibility of securing a Pre-Placement Offer (PPO) from our organization.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="reward-box" style={{ backgroundColor: currentColorIndex === 2 ? colors[0] : colors[1] }}>
                                            <h5>Cash prizes and Goodies</h5>
                                            <p>
                                                Campus Ambassadors stand to win enticing cash prizes, exclusive goodies, and additional rewards for their exceptional contributions, dedication, and efforts. Join us for enriching experiences and valuable incentives!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RewardSection;
