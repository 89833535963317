import Applyimg from './img/intern.png'
import Applyimg2 from './img/skills.png'
import Applyimg3 from './img/certified.png'
import Applyimg4 from './img/prize2.png'
import Applyimg5 from './img/other.png'
const ApplyData = [
    {
        img: `${Applyimg}`,
        ind: 1,
        heading: "Free Internship Opportunity",
        para: "Get a free Summer Internship Opportunity at Upflairs in your interested domain"
    },
    {
        img: `${Applyimg2}`,
        ind: 2,
        heading: "Acquire Skills",
        para: "Working with Upflairs will help you in acquiring more skills and professionalism. You will add many new dimensions to your personality aling the way by enhancing your skills"
    },
    {
        img: `${Applyimg3}`,
        ind: 3,
        heading: "Get Certificate",
        para: "Receive a Certificate of Participation as a Campus Ambassador upon completion of your tenure. Showcase it proudly on your resume for added motivation and recognition!"
    },
    {
        img: `${Applyimg4}`,
        ind: 4,
        heading: "Exciting Prices",
        para: "Unlock an array of thrilling prizes, encompassing delightful goodies and lucrative cash rewards upon achieving designated targets."
    },
    {
        img: `${Applyimg5}`,
        ind: 5,
        heading: "Other benefits",
        para: "Receive a performance-based Letter of Recommendation Chance to get PPO from the company"
    }
]


export default ApplyData;