import React, { useState } from 'react'
import './campusBanner.css'
import img from './img/campusLogo.png'
import img1 from './img/Upflairs.png'
import camAmb from './img/header.png'
import CampusForm from './CampusForm'


const CampusBanner = () => {
    
    const [IsModelOpen, setIsModelOpen] = useState(false)
    const FormOpenBTN = () =>{
        setIsModelOpen(true)
    }

    const closeModel = ()=>{
        setIsModelOpen(false)
    }

  return (
    <>
        <section className="CampusBanner">
            <div className="container">
                <div className="row">
                    <div className="col-xl-7 col-12">
                        <div className="campusbannerdiv">
                            <h3 className='text-uppercase text-center'>Upflairs pvt. Ltd.</h3>
                            <div className="campusProgram d-flex">
                                <div className="Campusheading">
                                    <h4 className='text-uppercase text-white'>Campus <span className='campusBannerSpan'>ambassador</span> program</h4>
                                </div>
                            </div>
                            <div className="headBottom d-flex justify-content-between align-items-center">
                                <div className="para">
                                    <p>Uniting campus voices for positive change.</p>
                                </div>
                                <button className="WhiteBTN button-40" onClick={FormOpenBTN}>
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 camBottom">
                        <img src={camAmb} alt="camp" />
                    </div>
                </div>
            </div>
        </section>
        {IsModelOpen && <CampusForm closeModel={closeModel}/>}
        
    </>
  )
}

export default CampusBanner