import React from 'react'

const Card = (props) => {

    console.log(props.customClass)
  return (
    <>
        <div className="CampusCard">
            <div className="CamCardImg">
                <img src={props.CardImg} alt="CardImg" />
            </div>
            <div className={`${props.customClass} CardCamDiv text-dark rounded-4`}>
                <h5>{props.heading}</h5>
                <p>
                    {props.para}
                </p>
            </div>
        </div>
    </>
  )
}

export default Card